import ObjectUtils from "@/utils/object-utils";
import _set from 'lodash/set';
import parseISO from "date-fns/parseISO";
import {
  SpecCreateBaseEntity,
  SpecManufactureProcessInputType,
  VisibleAdditionalIngredientColumn
} from "@/entities/specs/spec-entity";
import {
  AmountTypeEnum,
  LabelExpirationTypeEnum,
  LabelExtraFieldEnum,
  LabelExtraFieldEnumSpec
} from "@/entities/interfaces/i-label-entity";
import {BasicNutritionList} from "@/entities/nutrition-entity";
import {UserList} from "@/entities/user-entity";
import {
  SpecPackageImagePreparedType
} from "@/entities/specs/spec-attachment-entity";
import {notEmptyArrayRule} from "@/utils/validation-rules";

export default class SpecShareRequestEntity {
  public requestProductName!: string;
  public dueDate!: Date;
  public requiredFields!:RequiredFieldKey[];
  public assignees: UserList[] = [];

  constructor(init:Partial<SpecShareRequestEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.requiredFields = init.requiredFields || [];
    this.dueDate = parseISO(init.dueDate as any);
    this.assignees = init.assignees || [];
  }

  public getRequiredFieldsAsObject(value: any[]): any {
    const obj = {};

    const arrayFields: RequiredFieldKey[] = ['areas'];
    this.requiredFields.forEach((field:RequiredFieldKey) => {
      if (field.includes('nutrition.')) {
        field += ".original";
      }
      if (field.includes('.*')) {
        field = field.replace(/\.\*/g, "");
      }

      if (arrayFields.includes(field)) {
        value.push(notEmptyArrayRule);
      }

      _set(obj, field, value);
    });

    if (this.requiredFields.includes("areas")) {
    }
    return obj;
  }
}


export const RequiredFieldKeyPrefixSpecPackageImagePrepared = "attachments@contains:type=1&key=";
export const RequiredFields = {
  'intraCode': '基本タブ：管理ID',
  'releaseDate': '基本タブ：発売日',
  'applyDate': '基本タブ：適用日',
  'jicfsCategoryId': '基本タブ：JICFS分類',
  'brandType': '基本タブ：ブランド区分',
  'brandName': '基本タブ：ブランド名',
  'aboutUrl': '基本タブ：商品情報URL',
  'expirationType': '基本タブ：賞味期限区分',
  // 'expirationDaysBeforeOpen': '基本タブ：開封前の賞味・消費期限',
  // 'expirationDaysAfterOpen': '基本タブ：開封後の賞味・消費期限',
  'storageTemperatureRangeTypeBeforeShipping': '基本タブ：出荷前の保存温度帯',
  'storageTemperatureRangeTypeDuringShipping': '基本タブ：流通時の保存温度帯',
  'storageTemperatureRangeTypeBeforeOpen': '基本タブ：開封前（入荷後）の保存温度帯',
  'storageTemperatureRangeTypeAfterOpen': '基本タブ：開封後の保存温度帯',
  'expirationDaysCalculationBasisBeforeOpen': '基本タブ：開封前の賞味・消費期限設定の根拠',
  'expirationDaysCalculationBasisAfterOpen': '基本タブ：開封後の賞味・消費期限設定の根拠',
  'packingSpec': '基本タブ：荷姿規格',

  'seller.name': '企業タブ：販売者 名前',
  'seller.nameKana': '企業タブ：販売者 名前カナ',
  'seller.telno': '企業タブ：販売者 電話番号',
  'seller.faxno': '企業タブ：販売者 FAX番号',
  'seller.homepageUrl': "企業タブ：販売者 公式サイトURL",
  'maker.name': '企業タブ：製造者・加工者・輸入者 名前',
  'maker.nameKana': '企業タブ：製造者・加工者・輸入者 名前カナ',
  'maker.telno': '企業タブ：製造者・加工者・輸入者 電話番号',
  'maker.faxno': '企業タブ：製造者・加工者・輸入者 FAX番号',
  'maker.homepageUrl': "企業タブ：製造者・加工者・輸入者 公式サイトURL",
  'areas': "企業タブ：原産地・製造地",

  'nameAsIngredient': '原材料タブ：この商品を原材料として使用した場合の表示例',
  // 'ingredients.*.additivePurposeSynonymId': '原材料タブ：添加物の用途',
  'ingredients.*.displayName': '原材料タブ：一括表示上の表示名',
  'ingredients.*.madeInArea': '原材料タブ：原産地（最終加工地）',
  'ingredients.*.manufacturerName': '原材料タブ：製造者名',
  'ingredients.*.supplierName': '原材料タブ：仕入元名',
  'ingredients.*.origins': '原材料タブ：基原原料', // array
  'ingredients.*.hasAllergen': '原材料タブ：アレルギー物質の有無',
  'ingredients.*.hasGmo': '原材料タブ：遺伝子組換え食品の有無',

  'label.categoryType': '一括表示タブ：名称区分',
  'label.category': '一括表示タブ：名称',
  'label.ingredientsText': '一括表示タブ：原材料名',
  'label.amount': '一括表示タブ：内容量',
  'label.solidAmount': '一括表示タブ：固形量',
  'label.totalAmount': '一括表示タブ：内容総量',
  'label.expirationText': '一括表示タブ：賞味・消費期限テキスト',
  'label.preservationMethod': '一括表示タブ：保存方法',
  'label.madeInCountry': '一括表示タブ：原産国名',
  'label.madeInArea': '一括表示タブ：原料原産地',
  'label.precaution': '一括表示タブ：使用上の注意',
  'label.howToCook': '一括表示タブ：調理方法',
  'label.usage': '一括表示タブ：使用方法',
  'label.sanitizeMethod': '一括表示タブ：殺菌方法',
  'label.heatedBeforeFrozen': '一括表示タブ：凍結前加熱の有無',
  'label.needHeating': '一括表示タブ：加熱調理の必要性',
  // 'label.starchRatio': '一括表示タブ：でん粉含有率',
  // 'label.milkNonfatSolidRatio': '一括表示タブ：無脂乳固形分',
  // 'label.milkFatRatio': '一括表示タブ：乳脂肪分',

  'nutrition.calorie': '成分タブ：熱量',
  'nutrition.protein': '成分タブ：たんぱく質',
  'nutrition.lipid': '成分タブ：脂質',
  'nutrition.carb': '成分タブ：炭水化物',
  'nutrition.sugar': '成分タブ：糖質',
  'nutrition.saccharides': '成分タブ：糖類',
  'nutrition.fiber': '成分タブ：食物繊維',
  'nutrition.solubleFiber': '成分タブ：水溶性食物繊維',
  'nutrition.insolubleFiber': '成分タブ：不溶性食物繊維',
  'nutrition.salt': '成分タブ：食塩相当量',
  'nutrition.natrium': '成分タブ：ナトリウム',
  'nutrition.saturatedFattyAcids': '成分タブ：飽和脂肪酸',
  'nutrition.monoUnsaturatedFattyAcids': '成分タブ：一価不飽和脂肪酸',
  'nutrition.polyUnsaturatedFattyAcids': '成分タブ：多価不飽和脂肪酸',
  'nutrition.omega3': '成分タブ：n-3 系脂肪酸',
  'nutrition.omega6': '成分タブ：n-6 系脂肪酸',
  'nutrition.cholesterol': '成分タブ：コレステロール',
  'nutrition.transFattyAcids': '成分タブ：トランス脂肪酸',
  'nutrition.water': '成分タブ：水分',
  'nutrition.ash': '成分タブ：灰分',
  'nutrition.zinc': '成分タブ：亜鉛',
  'nutrition.potassium': '成分タブ：カリウム',
  'nutrition.calcium': '成分タブ：カルシウム',
  'nutrition.chromium': '成分タブ：クロム',
  'nutrition.selenium': '成分タブ：セレン',
  'nutrition.iron': '成分タブ：鉄',
  'nutrition.copper': '成分タブ：銅',
  'nutrition.magnesium': '成分タブ：マグネシウム',
  'nutrition.manganese': '成分タブ：マンガン',
  'nutrition.molybdenum': '成分タブ：モリブデン',
  'nutrition.iodine': '成分タブ：ヨウ素',
  'nutrition.phosphorous': '成分タブ：リン',
  'nutrition.niacin': '成分タブ：ナイアシン',
  'nutrition.pantothenicAcid': '成分タブ：パントテン酸',
  'nutrition.biotin': '成分タブ：ビオチン',
  'nutrition.vitaminB1': '成分タブ：ビタミンB1',
  'nutrition.vitaminB2': '成分タブ：ビタミンB2',
  'nutrition.vitaminB6': '成分タブ：ビタミンB6',
  'nutrition.vitaminB12': '成分タブ：ビタミンB12',
  'nutrition.vitaminC': '成分タブ：ビタミンC',
  'nutrition.vitaminD': '成分タブ：ビタミンD',
  'nutrition.vitaminK': '成分タブ：ビタミンK',
  'nutrition.folicAcid': '成分タブ：葉酸',
  'nutrition.vitaminA': '成分タブ：ビタミンA',
  'nutrition.retinol': '成分タブ：レチノール',
  'nutrition.alphaCarotene': '成分タブ：αカロテン',
  'nutrition.betaCarotene': '成分タブ：βカロテン',
  'nutrition.betaCryptoxanthin': '成分タブ：βクリプトキサンチン',
  'nutrition.betaCaroteneEquivalent': '成分タブ：βカロテン当量',
  'nutrition.alphaTocopherol': '成分タブ：ビタミンE',
  'nutrition.betaTocopherol': '成分タブ：βトコフェロール',
  'nutrition.gammaTocopherol': '成分タブ：γトコフェロール',
  'nutrition.deltaTocopherol': '成分タブ：δトコフェロール',

  'packageGmoText': '包材タブ：遺伝子組替え表示（一括表示外）',
  'packageMadeInAreasText': '包材タブ：原料原産地の表示（一括表示外）',
  'packageContaminationAlertText': '包材タブ：アレルギー物質の混入（コンタミネーション）注意表示',
  'isRiceTraceability': '包材タブ：米トレーサビリティ 対象有無',
  [RequiredFieldKeyPrefixSpecPackageImagePrepared + SpecPackageImagePreparedType.PieceFront]: `包材タブ：画像「${SpecPackageImagePreparedType.PieceFront}」`,
  [RequiredFieldKeyPrefixSpecPackageImagePrepared + SpecPackageImagePreparedType.PieceLabel]: `包材タブ：画像「${SpecPackageImagePreparedType.PieceLabel}」`,
  [RequiredFieldKeyPrefixSpecPackageImagePrepared + SpecPackageImagePreparedType.Outermost]: `包材タブ：画像「${SpecPackageImagePreparedType.Outermost}」`,
  // 'riceTraceabilityDisplayPlace': '包材タブ：米トレーサビリティ 産地名の表示場所',

  'manufacturingProcessText': '製造タブ：製造工程（テキスト）',
  'manufacturingProcess@tableSimple': '製造タブ：製造工程（簡易入力）',
  'useMetalDetector': '製造タブ：金属探知機の使用有無',
  // 'metalDetectorFe': '製造タブ：金属探知機Fe値',
  // 'metalDetectorSus': '製造タブ：金属探知機SUS値',
  'useWeightChecker': '製造タブ：ウェイトチェッカーの使用有無',
  // 'weightCheckerMin': '製造タブ：ウェイトチェッカー下限値',
  // 'weightCheckerMax': '製造タブ：ウェイトチェッカー上限値',
  'useXRayDetector': '製造タブ：X線検出器の使用有無',
  'finalSanitizeMethod': '製造タブ：包装前後の最終殺菌方法',
  'coolingMethod': '製造タブ：最終殺菌後の冷却方法',
  'contaminationPreventionMeasure': '製造タブ：コンタミネーション防止策',
} as const;

export type RequiredFieldKey = (keyof typeof RequiredFields) & string;

export function setInitialValueFromRequiredFields(spec: SpecCreateBaseEntity, keys: RequiredFieldKey[]) {
  if (
    keys.includes("storageTemperatureRangeTypeBeforeShipping")
    || keys.includes("storageTemperatureRangeTypeDuringShipping")
    || keys.includes("expirationDaysCalculationBasisBeforeOpen")
    || keys.includes("expirationDaysCalculationBasisAfterOpen")
  ) {
    spec.isExpirationDetail = true;
  }

  const merge = <T>(original: T[], value:T) => {
    if (!original.includes(value)) {
      original.push(value);
    }
  }
  if (keys.includes("label.solidAmount")) {
    spec.label.amountType = AmountTypeEnum.Fix;
  }
  if (keys.includes("label.totalAmount")) {
    spec.label.amountType = AmountTypeEnum.FixAndTotal;
  }
  if (keys.includes("label.expirationText")) {
    spec.label.expirationType = LabelExpirationTypeEnum.BestBy;
  }
  if (keys.includes("label.madeInCountry")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnumSpec.MadeInCountry);
  }
  if (keys.includes("label.madeInArea")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnumSpec.MadeInArea);
  }
  if (keys.includes("label.precaution")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.Precaution);
  }
  if (keys.includes("label.howToCook")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.HowToCook);
  }
  if (keys.includes("label.usage")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.Usage);
  }
  if (keys.includes("label.sanitizeMethod")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.SanitizeMethod);
  }
  if (keys.includes("label.heatedBeforeFrozen")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.HeatedBeforeFrozen);
  }
  if (keys.includes("label.needHeating")) {
    merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.NeedHeating);
  }
  // if (keys.includes("label.starchRatio")) {
  //   merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.StarchRatio);
  // }
  // if (keys.includes("label.milkNonfatSolidRatio")) {
  //   merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.MilkNonfatSolidRatio);
  // }
  // if (keys.includes("label.milkFatRatio")) {
  //   merge(spec.label.visibleExtraFieldKeys, LabelExtraFieldEnum.MilkFatRatio);
  // }

  const basicNutritionKeys = BasicNutritionList.map(n => n.key).concat(['salt']);
  if (keys.filter(k => k.startsWith('nutrition.') && !basicNutritionKeys.includes(k)).length > 0) {
    spec.nutrition.isDetail = true;
  }

  if (keys.includes("manufacturingProcessText")) {
    spec.manufacturingProcessInputType = SpecManufactureProcessInputType.Text;
  } else if (keys.includes('manufacturingProcess@tableSimple')) {
    spec.manufacturingProcessInputType = SpecManufactureProcessInputType.TableSimple;
  }

  Object.entries(VisibleAdditionalIngredientColumnRequiredKeyMap).forEach(([key, val]) => {
    if (keys.includes(val as RequiredFieldKey)) {
      merge(spec.visibleAdditionalIngredientCols, key as VisibleAdditionalIngredientColumn);
    }
  });

  keys.filter((k) => k.startsWith(RequiredFieldKeyPrefixSpecPackageImagePrepared)).forEach((k: RequiredFieldKey) => {
    const key = new RegExp(`${RequiredFieldKeyPrefixSpecPackageImagePrepared}(.*)`).exec(k)![1];
    if (!spec.visiblePackageImageKeys.includes(key)) {
      spec.visiblePackageImageKeys.push(key);
    }
  });
}

export const VisibleAdditionalIngredientColumnRequiredKeyMap: Partial<Record<VisibleAdditionalIngredientColumn, RequiredFieldKey>> = {
  [VisibleAdditionalIngredientColumn.Area]: 'ingredients.*.madeInArea',
  [VisibleAdditionalIngredientColumn.Manufacturer]: 'ingredients.*.manufacturerName',
  [VisibleAdditionalIngredientColumn.Supplier]: 'ingredients.*.supplierName',
  [VisibleAdditionalIngredientColumn.OriginMaterial]: 'ingredients.*.origins',
};
