import ObjectUtils from "@/utils/object-utils";
import {AreaSuffixForProcess} from "@/entities/ingredient-made-in-area-entity";

export class SpecAreaEntity {
  public name!:string;

  constructor(init:Partial<SpecAreaEntity>) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public getLabelDisplayName(isProcessed: boolean): string {
    return isProcessed ? `${this.name}${AreaSuffixForProcess}` : this.name;
  }
}
