import ObjectUtils from "@/utils/object-utils";

export enum AdditiveType {
  GeneralFood = 2, // 一般飲食物
  Existing = 3, // 既存添加物
  PurposeHeiki = 5, // 酸化防止剤, 調味料（アミノ酸）,調味料（核酸等）等、用途名が直接指定されている場合
  PurposeIkkatsu = 6, // イーストフード、ガムベースなど
  CustomSeasoning = 8, // 調味料（アミノ酸）
  PurposeManufactureAgent = 14, // 用途（製造用剤）
}

export enum AdditiveSeasoningType {
  AminoAcid = 1,
  NucleicAcid = 2,
  OrganicAcid = 3,
  InorganicSalt = 4,
}

export default class AdditiveEntity {
  public id!:number;
  public name!:string;
  public type!:AdditiveType;

  public additiveSynonyms:AdditiveSynonymEntity[] = [];
  public additivePurposes:AdditivePurposeEntity[] = [];
  public functionGroup:AdditiveFunctionGroup|null = null;
  public canGroupAsPolysaccharideThickener!:boolean;

  public seasoningType!:AdditiveSeasoningType|null;

  public selectableAdditivePurposeSynonyms:SelectableAdditivePurposeSynonym[] = [];

  public get hasFunctionGroup():boolean {
    return !!this.functionGroup;
  }

  public get isTypePurposeHeiki(): boolean {
    return this.type === AdditiveType.PurposeHeiki;
  }
  public get isPurposeManufactureAgentType(): boolean {
    return this.type === AdditiveType.PurposeManufactureAgent;
  }

  constructor(init:Partial<AdditiveEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.additiveSynonyms) {
      this.additiveSynonyms = init.additiveSynonyms.map(a => new AdditiveSynonymEntity(a));
    }
    if (init.additivePurposes) {
      this.additivePurposes = init.additivePurposes.map(a => new AdditivePurposeEntity(a));
    }
    if (init.functionGroup) {
      this.functionGroup = new AdditiveFunctionGroup(init.functionGroup);
    }

    if(init.selectableAdditivePurposeSynonyms) {
      this.selectableAdditivePurposeSynonyms = init.selectableAdditivePurposeSynonyms;
    }
  }

  public getDefaultAdditivePurposeSynonymId():number|null {
    const typicals = this.selectableAdditivePurposeSynonyms.filter(a => a.typical);
    if (typicals.length === 1) {
      // 用途別名の選択肢が1つだけなら、それを選択する
      return typicals[0].id;
    } else {
      return null;
    }
  }

  public getSeasoningTypeLabel(): string|null {
    if (!this.seasoningType) return null;
    return {
      [AdditiveSeasoningType.AminoAcid]: 'アミノ酸',
      [AdditiveSeasoningType.NucleicAcid]: '核酸',
      [AdditiveSeasoningType.OrganicAcid]: '有機酸',
      [AdditiveSeasoningType.InorganicSalt]: '無機塩',
    }[this.seasoningType.toString()] || null;
  }
}

export enum AdditiveSynonymType {
  Material = 1,
  // Alias = 2,
  // Simplified = 3,
  // Outlaw = 4,
}

export class AdditiveSynonymEntity {
  public id!:number;
  public additiveId!:number;
  public synonym!:string;
  public type!: AdditiveSynonymType;

  constructor(init:Partial<AdditiveSynonymEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}

export enum PurposeCategoryLabel {
  NEED_HEIKI = 1,
  NEED_IKKATSU = 2,
  MANUFACTURE_AGENT = 9,
}

export class AdditivePurposeEntity {
  public id!:number|null;
  public name!:string;
  public category!:PurposeCategoryLabel;
  public isSeasoning!:boolean;

  public additivePurposeSynonyms:AdditivePurposeSynonymEntity[] = [];

  constructor(init:Partial<AdditivePurposeEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.additivePurposeSynonyms) {
      this.additivePurposeSynonyms = init.additivePurposeSynonyms.map(a => new AdditivePurposeSynonymEntity(a, this));
    }
  }
}

export interface SelectableAdditivePurposeSynonym {
  id: number;
  synonym: string;
  typical: boolean;
}

export class AdditivePurposeSynonymEntity {
  public id!:number;
  public synonym!:string;

  public additivePurposeId!:number;
  public additivePurpose!:AdditivePurposeEntity;

  constructor(init:Partial<AdditivePurposeSynonymEntity> = {}, parent?:AdditivePurposeEntity) {
    ObjectUtils.assignLiteralFields(this, init);

    if (parent) {
      this.additivePurpose = parent;
    } else if (init.additivePurpose) {
      this.additivePurpose = new AdditivePurposeEntity(init.additivePurpose);
    }
  }

  public getLabelName(additive:AdditiveEntity) {
    return AdditivePurposeSynonymEntity.getLabelNameStatic(this.synonym, this.additivePurpose && this.additivePurpose.isSeasoning, additive);
  }
  public static getLabelNameStatic(synonym: string, isSeasoning:boolean, additive:AdditiveEntity) {
    if(isSeasoning) {
      return synonym + `（${additive.getSeasoningTypeLabel()}）`;
    } else {
      return synonym;
    }
  }
}


export class AdditiveFunctionGroup {
  public type!:AdditiveFunctionGroupType;
  public name!:string;
  public symbol!:string|null;

  constructor(init:Partial<AdditiveFunctionGroup>) {
    ObjectUtils.assignLiteralFields(this, init);
  }
}
export enum AdditiveFunctionGroupType {
  Acid =  1, // 酸
  AcidBase =  2, // 酸塩
  Compound =  3, // 化合物
}
