import {
  createDecimalRule,
  createMaxStringRule,
  createMinRule, createProhibitedCharsRule,
  requiredOnBlurRule,
  requiredOnChangeRule, requiredRule,
  typeNonNegativeIntRule,
  typeNumberOrEmptyRule
} from '@/utils/validation-rules';

import {formatDate} from "@/utils/date-utils";

import ObjectUtils from "@/utils/object-utils";
import IFile from "@/contracts/i-file";
import SpecPackingFormEntity, {
  PackingUnitTypeEnum,
  ValidatorRules as SpecPackingFormValidatorRules,
} from "@/entities/specs/spec-packing-form-entity";
import SpecNutritionEntity, {ValidatorRules as NutritionValidatorRules,} from "@/entities/specs/spec-nutrition-entity";
import SpecPackageMaterialEntity, {
  ValidatorRules as SpecPackingMaterialValidatorRules,
} from "@/entities/specs/spec-package-material-entity";
import SpecPackageCertificationMarkEntity, {
  ValidatorRules as SpecPackageCertificationMarkValidatorRules,
} from "@/entities/specs/spec-package-certification-mark-entity";
import SpecQualityPreservationMethodEntity, {
  ValidatorRules as QualityPreservationMethodTypeValidatorRules
} from "@/entities/specs/spec-quality-preservation-method-entity";
import SpecLabelCustomValueEntity, {
  ValidatorRules as LabelCustomValueValidatorRules,
} from "@/entities/specs/spec-label-custom-value-entity";
import SpecIngredientEntity, {
  HydrogenatedOilType,
  ValidatorRules as IngredientValidatorRules
} from "@/entities/specs/spec-ingredient-entity";
import SpecInspectionEntity, {SpecInspectionType} from "@/entities/specs/spec-inspection-entity";
import {JicfsCategory} from "@/repositories/master/jicfs-category-repository";
import parseISO from "date-fns/parseISO";
import {MakerType, SpecPartnerEntity, ValidatorRules as PartnerValidatorRules} from "@/entities/specs/partner-entity";
import cloneDeep from "lodash/cloneDeep";
import uniq from "lodash/uniq";
import SpecLabelEntity from "@/entities/specs/spec-label-entity";
import SpecCertificationEntity, {
  ValidatorRules as SpecCertificationValidatorRules,
} from "@/entities/specs/spec-certification-entity";
import {SpecPartnerFactoryEntity} from "@/entities/specs/partner-factory-entity";
import SpecAttachmentEntity, {
  SpecAttachmentType,
  SpecDocumentAttachmentPreparedType,
  SpecPackageImagePreparedType
} from "@/entities/specs/spec-attachment-entity";
import SpecManufactureProcessEntity, {
  ValidatorRules as ProcessValidatorRules
} from "@/entities/specs/spec-manufacture-process-entity";
import SpecCustomValueEntity, {SpecCustomValueCategory} from "@/entities/specs/spec-custom-value-entity";
import SpecReferringSpecEntity from "@/entities/specs/spec-referring-spec-entity";
import isDate from "date-fns/isDate";
import {i18n} from "@/bootstraps/locale";
import {BrandTypesLang} from "@/lang/enum/brand-types-lang";
import SpecXRayDetectionEntity from "@/entities/specs/spec-x-ray-detection-entity";
import SpecIngredientCollection from "@/entities/specs/spec-ingredient-collection";
import {isCustomValueEmpty} from "@/entities/interfaces/i-custom-value";
import {ApprovalRequestEntity, IHasApprovalRequest} from "@/entities/approval-request-entity";

class SpecMetaSimpleEntity {
  public id!:number;
  public company!: { id:number; name:string; nameKana:string; }
  public hasAnySubmission!:boolean;

  constructor(init:Partial<SpecMetaSimpleEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
    this.company = init.company!;
  }
}

export enum FoodType {
  Fresh = 1,
  Process = 2,
  Additive = 3,
}
export const FoodTypeDict = {
  [FoodType.Fresh]: i18n.t('生鮮食品'),
  [FoodType.Process]: i18n.t('加工食品'),
  [FoodType.Additive]: i18n.t('添加物'),
}
export enum FoodTypeForSpec {
  Fresh = 1,
  Process = 2,
  Additive = 3,
  AdditiveFormulation = 4
}
export const FoodTypeDictForSpec = {
  [FoodTypeForSpec.Fresh]: i18n.t('生鮮食品'),
  [FoodTypeForSpec.Process]: i18n.t('加工食品'),
  [FoodTypeForSpec.Additive]: i18n.t('添加物'),
  [FoodTypeForSpec.AdditiveFormulation]: i18n.t('添加物製剤'),
}

export class SpecBaseEntity implements IHasNutritionExtra {
  public referredProductId:number|null = null;

  public readonly specMetaId!:number;

  public name!:string;
  public nameKana:string|null = null;
  public foodType!:FoodTypeForSpec;
  public areas: SpecAreaEntity[] = [];
  public jicfsCategoryId:number|null = null;
  public formulationAdditiveSynonymId: number|null = null;
  public intraCode:string|null = null;
  public releaseDate:Date|null = null;
  public applyDate:Date|null = null;
  public brandName:string|null = null;
  public brandType:number|null = null;
  public aboutUrl: string|null = null;

  public isExpirationDetail:boolean = false;
  public expirationType:ExpirationTypeEnum|null = null;
  public expirationDaysBeforeOpen:number|null = null;
  public expirationDateUnitBeforeOpen:ExpirationDateUnit = ExpirationDateUnit.Day;
  public expirationDaysAfterOpen:number|null = null;
  public expirationDateUnitAfterOpen:ExpirationDateUnit = ExpirationDateUnit.Day;
  public storageTemperatureRangeTypeBeforeShipping:number|null = null;
  public storageTemperatureRangeTypeDuringShipping:number|null = null;
  public storageTemperatureRangeTypeBeforeOpen:number|null = null;
  public storageTemperatureRangeTypeAfterOpen:number|null = null;
  public expirationDaysCalculationBasisBeforeOpen:string|null = null;
  public expirationDaysCalculationBasisAfterOpen:string|null = null;
  public deteriorationReason: string|null = null;

  public expirationFormatType:number|null = ExpirationTypeEnum.BestBy;
  public manufactureDateFormatType:number|null = null;

  public appealText:string = '';
  public usageText:string = '';
  public precautionText:string = '';

  public isAlcohol:boolean = false;
  public alcoholCode:string|null = null;
  public alcoholRatio:number|null = null;

  public certifications:SpecCertificationEntity[] = [];

  public referringSpec:SpecReferringSpecEntity|null = null;

  public seller!:SpecPartnerEntity;
  public paramSellerOperatorName: string|null = null;
  public paramSellerNote: string|null = null;
  public maker!:SpecPartnerEntity;
  public makerType:MakerType = MakerType.Manufacturer;
  public makerFactory:SpecPartnerFactoryEntity|null = null;

  public visibleAdditionalIngredientCols: VisibleAdditionalIngredientColumn[] = [
    VisibleAdditionalIngredientColumn.Area,
  ];
  public ingredients:SpecIngredientEntity[] = [];

  public isAmountRatioInTotal: boolean = false;
  public ingredientCustomCol1:string|null = null;
  public ingredientCustomCol2:string|null = null;
  public ingredientCustomCol3:string|null = null;
  public nameAsIngredient:string|null = null;
  public noteForIngredientList:string|null = null;

  public label!: SpecLabelEntity;
  public labelCustomValues: SpecLabelCustomValueEntity[] = [];

  public isPackingSettingDetail:boolean = false;
  public packingSpec:string|null = null;
  public isWeightVariable:boolean = false;
  public showMlOnPackingForms:boolean = false;
  public packingFormWeightUnit: PackingFormWeightUnitType = PackingFormWeightUnitType.Gram;
  public minPackingFormType:PackingUnitTypeEnum|null = null;

  public packingForms:SpecPackingFormEntity[]|null = null;

  public nutrition!:SpecNutritionEntity;
  public nutritionExtras: NutritionExtra[] = [];

  public packageMaterials:SpecPackageMaterialEntity[] = [];
  public packageCertificationMarks:SpecPackageCertificationMarkEntity[] = [];
  public packageGmoText:string|null = null;
  public packageMadeInAreasText:string|null = null;
  public packageContaminationAlertText:string|null = null;
  public isRiceTraceability:boolean|null = null;
  public riceTraceabilityDisplayPlace:string|null = null;
  public packageNote:string|null = null;

  public manufacturingProcessInputType: SpecManufactureProcessInputType = SpecManufactureProcessInputType.TableSimple;
  public manufacturingProcessText : string|null = null;
  public manufactureProcesses:SpecManufactureProcessEntity[] = [];

  public useMetalDetector: boolean|null = null;
  public metalDetectorFe: number|null = null;
  public metalDetectorSus: number|null = null;
  public metalDetectorNonFe: number|null = null;
  public metalDetectorNote: string|null = null;
  public useWeightChecker: boolean|null = null;
  public weightCheckerMin: number|null = null;
  public weightCheckerMax: number|null = null;
  public useXRayDetector: boolean|null = null;
  public xRayDetections: SpecXRayDetectionEntity[] = [];
  public contaminationCheckMethod: string|null = null;

  public finalSanitizeMethod: string|null = null;
  public coolingMethod: string|null = null;

  public hasContaminationInSameFactory:boolean|null = null;
  public contaminationAllergenIdListInSameFactory:number[] = [];
  public hasContaminationInSameLine:boolean|null = null;
  public contaminationAllergenIdListPotentially:number[] = [];
  public contaminationPreventionMeasure:string|null = null;
  public inspections: SpecInspectionEntity[] = [];
  public qualityPreservationMethods: SpecQualityPreservationMethodEntity[] = [];

  public inspectionsPhysical: SpecInspectionEntity[] = [];
  public inspectionsChemical: SpecInspectionEntity[] = [];
  public inspectionsMicrobe: SpecInspectionEntity[] = [];

  public manufactureNote:string|null = null;

  public customValues:SpecCustomValueEntity[] = [];

  public attachments:SpecAttachmentEntity[] = [];
  public visiblePackageImageKeys:string[] = [SpecPackageImagePreparedType.PieceFront, SpecPackageImagePreparedType.PieceLabel];
  public visibleManufactureAttachmentKeys:string[] = [SpecDocumentAttachmentPreparedType.HYGIENE_CHECK_SHEET, SpecDocumentAttachmentPreparedType.QUALITY_EVIDENCE1 ];

  public constructor() {
  }

  public get packingFormPiece():SpecPackingFormEntity|null {
    if(!this.packingForms) return null;
    return this.packingForms.find(pf => pf.isPiece) || null;
  }
  public get packingFormWeightUnitLabel(): string {
    if (this.packingFormWeightUnit === PackingFormWeightUnitType.Ton) {
      return 't';
    }
    return PackingFormWeightUnitTypeDict[this.packingFormWeightUnit];
  }

  public shouldSumChildrenAmount(ing: SpecIngredientEntity):boolean {
    return this.isAmountRatioInTotal && ing.hasAnyChildren;
  }

  public get shouldHaveFactory(): boolean {
    return this.makerType === MakerType.Manufacturer || this.makerType === MakerType.Processor;
  }
  public get shouldShowArea(): boolean {
    return this.foodType === FoodTypeForSpec.Fresh || this.foodType === FoodTypeForSpec.Process;
  }
  public get isFoodTypeProcess(): boolean {
    return this.foodType === FoodTypeForSpec.Process;
  }
}

export class SpecCreateBaseEntity extends SpecBaseEntity {
  public ingredientsNested:SpecIngredientEntity[] = [];

  public $hasReferringSpec = false;

  public customValuesPerCategory!: {[key: string] : SpecCustomValueEntity[]};

  public constructor() {
    super();

    this.seller = new SpecPartnerEntity();
    this.maker = new SpecPartnerEntity();

    this.nutrition = new SpecNutritionEntity();
    this.label = new SpecLabelEntity();

    this.customValuesPerCategory = Object.values(SpecCustomValueCategory).reduce((acc, value ) => {
      return {...acc, [value]: this.customValues.filter(c => c.category === value)}
    }, {});
  };

  public getNameAsIngredient(additiveSynonymListFormulation: TAdditiveSynonymListItem[]): string | null {
    if (this.foodType === FoodTypeForSpec.Fresh || this.foodType === FoodTypeForSpec.Additive) {
      return this.ingredients.length > 0 ? this.ingredients[0].getLabelName() : '';
    } else if (this.foodType === FoodTypeForSpec.AdditiveFormulation) {
      const found = additiveSynonymListFormulation.find(a => a.synonymId === this.formulationAdditiveSynonymId);
      return found ? found.label : '';
    } else {
      return this.nameAsIngredient;
    }
  }

  public serialize(): SpecCreateBaseEntity {
    const data = cloneDeep(this) as SpecCreateBaseEntity;

    // 保存はingredientsNestedを使って行われる(子にparent_idを設定しないといけないため）
    // ingredientsはvalidation用に使われる
    data.ingredients = data.ingredients.filter(i => !i.isEmpty).map(i => i.serialize());
    data.ingredientsNested = data.ingredientsNested.filter(i => !i.isEmpty).map(i => i.serialize());

    data.qualityPreservationMethods = data.qualityPreservationMethods.filter(q => !q.isEmpty);

    data.label = data.label.serialize();
    if (data.label.visibleExtraFieldKeys.includes(LabelExtraFieldEnum.CustomValues)) {
      data.labelCustomValues = data.labelCustomValues.filter(d => !d.isEmpty);
    } else {
      data.labelCustomValues = [];
    }

    if (!data.useXRayDetector) {
      data.xRayDetections = [];
    }

    if(data.certifications) {
      data.certifications = data.certifications.filter(c => !c.isEmpty);
    }

    if(data.makerFactory) {
      data.makerFactory.certifications = data.makerFactory.certifications.filter(c => !c.isEmpty);
    }


    data.attachments = data.attachments.filter(a => {
      const visibleDocuments = data.visibleManufactureAttachmentKeys.concat(SpecDocumentAttachmentPreparedType.MANUFACTURE_PROCESS, SpecDocumentAttachmentPreparedType.ORIGIN_CERTIFICATION);
      return (a.type === SpecAttachmentType.Document && visibleDocuments.includes(a.key))
        || (a.type === SpecAttachmentType.PackageImage && data.visiblePackageImageKeys.includes(a.key));
    });

    data.inspections = [
      ...data.inspectionsPhysical,
      ...data.inspectionsChemical,
      ...data.inspectionsMicrobe
    ].filter(i => !i.isEmpty);

    if (!data.hasContaminationInSameFactory) {
      data.contaminationAllergenIdListInSameFactory = [];
    }
    if (!data.hasContaminationInSameLine) {
      data.contaminationAllergenIdListPotentially = [];
    }

    data.customValues = Object.values(this.customValuesPerCategory).flat().filter(i => !isCustomValueEmpty(i));

    data.nutrition = data.nutrition.serialize() as any;
    data.nutritionExtras = data.nutritionExtras.map(e => e.serialize()) as any;

    if (!this.$hasReferringSpec) {
      data.referringSpec = null;
    }

    return data;
  }

  public createPackingFormLabel(): string {
    const weightUnit = this.packingFormWeightUnitLabel;
    if (this.isPackingSettingDetail) {
      // 例）240g x 20袋 x 2ケース
      let prevUnit = weightUnit;
      return this.packingForms!
        .filter(pf => pf.active)
        .sort((a, b) => a.type - b.type)
        .map((val:SpecPackingFormEntity) => {
          const amount = (val.type === PackingUnitTypeEnum.Piece) ? val.weight : val.quantity
          const res = (amount || '-') + prevUnit;
          prevUnit = val.unit || '';
          return res;
        })
        .concat([`1${prevUnit}`])
        .join(' x ');
    } else {
      return (this.packingFormPiece!.weight || '-') + weightUnit;
    }
  }

  // サーバー側でnestする、あるいはimmutableにnestするという案もあるが、
  // ネストされたものとそうでないものの参照を同一に保ちたかったから一旦こうしてる
  // TODO: refactor
  public setNestedIngredientsWithMutating() {
    this.ingredients.forEach((ing:SpecIngredientEntity) => {
      if(ing.parentId) {
        const parent =  this.ingredients.find(i => i.id === ing.parentId)!;
        parent.children.push(ing);
      } else {
        this.ingredientsNested.push(ing);
      }
    });
  }

  // TODO: refactor
  public getAmount(ing: SpecIngredientEntity):number {
    const collection = new SpecIngredientCollection(this, this.ingredients);
    return collection.getAmount(ing);
  }
  public getRootAmountSum(): number {
    const collection = new SpecIngredientCollection(this, this.ingredients);
    return collection.getRootAmountSum();
  }

  public get hasAnySubmission(): boolean {
    return false;
  }
}

function constructSpec(spec: SpecBaseEntity, init:SpecBaseEntity) {
  if(init.releaseDate) {
    spec.releaseDate = isDate(init.releaseDate) ? init.releaseDate : parseISO((init as any).releaseDate);
  }
  if(init.applyDate) {
    spec.applyDate = isDate(init.applyDate) ? init.applyDate : parseISO((init as any).applyDate);
  }

  if (init.visibleAdditionalIngredientCols) {
    spec.visibleAdditionalIngredientCols = init.visibleAdditionalIngredientCols;
  }

  if (init.ingredients) {
    spec.ingredients = init.ingredients.map(pf => new SpecIngredientEntity(pf));
  }
  if (init.packingForms) {
    spec.packingForms = init.packingForms.map(pf => new SpecPackingFormEntity(pf));
  }

  spec.certifications = init.certifications ?
    init.certifications.map(pf => new SpecCertificationEntity(pf)) :
    [];

  spec.areas = (init.areas || []).map(pf => new SpecAreaEntity(pf));

  if (init.referringSpec) {
    spec.referringSpec = new SpecReferringSpecEntity(init.referringSpec);
  }

  if (init.seller) {
    spec.seller = new SpecPartnerEntity(init.seller);
    spec.paramSellerOperatorName = init.seller.operatorName;
    spec.paramSellerNote = init.seller.note;
  } else {
    spec.seller = new SpecPartnerEntity({});
  }
  spec.maker = new SpecPartnerEntity(init.maker || {});

  if (init.makerFactory && init.makerFactory.name) {
    spec.makerFactory = new SpecPartnerFactoryEntity(init.makerFactory);
  }

  if (init.labelCustomValues) {
    spec.labelCustomValues = init.labelCustomValues.map(pf => new SpecLabelCustomValueEntity(pf));
  }

  spec.packageMaterials = init.packageMaterials ?
    init.packageMaterials.map(pf => new SpecPackageMaterialEntity(pf)) :
    [];

  spec.packageCertificationMarks = init.packageCertificationMarks ?
    init.packageCertificationMarks.map(pf => new SpecPackageCertificationMarkEntity(pf)) :
    [];

  spec.contaminationAllergenIdListInSameFactory = init.contaminationAllergenIdListInSameFactory || [];
  spec.contaminationAllergenIdListPotentially = init.contaminationAllergenIdListPotentially || [];

  spec.xRayDetections = (init.xRayDetections || []).map(pf => new SpecXRayDetectionEntity(pf));

  if (init.inspections) {
    spec.inspections = init.inspections.map(pf => new SpecInspectionEntity(pf));
    spec.inspectionsPhysical = spec.inspections.filter(i => i.type === SpecInspectionType.Physical);
    spec.inspectionsChemical = spec.inspections.filter(i => i.type === SpecInspectionType.Chemical);
    spec.inspectionsMicrobe = spec.inspections.filter(i => i.type === SpecInspectionType.Microbe);
  }

  spec.qualityPreservationMethods = init.qualityPreservationMethods ?
    init.qualityPreservationMethods.map(pf => new SpecQualityPreservationMethodEntity(pf)) :
    [];

  spec.manufactureProcesses = init.manufactureProcesses ?
    init.manufactureProcesses.map(pf => new SpecManufactureProcessEntity(pf)) :
    [];

  spec.visiblePackageImageKeys = init.visiblePackageImageKeys || [];
  spec.visibleManufactureAttachmentKeys = init.visibleManufactureAttachmentKeys || [];

  if (init.customValues) {
    spec.customValues = init.customValues.map(pi => new SpecCustomValueEntity(pi));
  }

  if (init.attachments) {
    spec.attachments = init.attachments.map(pi => new SpecAttachmentEntity(pi));
  }
}

export class SpecUpdateEntity extends SpecCreateBaseEntity  {
  public readonly id!:number;
  public readonly meta!:SpecMetaSimpleEntity;

  constructor(init:Partial<SpecUpdateEntity>) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    this.nutrition = new SpecNutritionEntity(init.nutrition);
    this.nutritionExtras = (init.nutritionExtras || []).map(e => new NutritionExtra(e));
    this.label = new SpecLabelEntity(init.label);

    constructSpec(this, init as SpecUpdateEntity);

    if (init.referringSpec) {
      this.$hasReferringSpec = true;
    }

    this.meta = new SpecMetaSimpleEntity(init.meta);

    this.setNestedIngredientsWithMutating(); // childrenを作る

    this.customValuesPerCategory = Object.values(SpecCustomValueCategory).reduce((acc, value ) => {
      return {...acc, [value]: this.customValues.filter(c => c.category === value)}
    }, {});
  };

  public get hasAnySubmission(): boolean {
    return this.meta ? this.meta.hasAnySubmission : false;
  }
}

export default class SpecEntity extends SpecBaseEntity implements IHasApprovalRequest {
  public readonly id!:number;
  public readonly meta!:SpecMetaSimpleEntity;

  public readonly isPublished!:boolean;
  public readonly jicfsCategory:JicfsCategory|null = null;
  public readonly formulationAdditiveSynonym: AdditiveSynonymEntity|null = null;
  public createdAt:string = '';

  public approvalRequest:ApprovalRequestEntity | null = null;

  public toSpecUpdateEntity(): SpecUpdateEntity {
    return new SpecUpdateEntity(this);
  }

  constructor(init: SpecEntity) {
    super();
    ObjectUtils.assignLiteralFields(this, init);

    this.nutrition = new SpecNutritionEntity(init.nutrition);
    this.nutritionExtras = (init.nutritionExtras || []).map(e => NutritionExtra.parseFromApi(e as any));
    this.label = new SpecLabelEntity(init.label);

    constructSpec(this, init);

    this.meta = new SpecMetaSimpleEntity(init.meta);

    if (init.jicfsCategory) {
      this.jicfsCategory = new JicfsCategory(init.jicfsCategory);
    }
    if (init.formulationAdditiveSynonym) {
      this.formulationAdditiveSynonym = new AdditiveSynonymEntity(init.formulationAdditiveSynonym);
    }
    if (init.approvalRequest) {
      this.approvalRequest = new ApprovalRequestEntity(init.approvalRequest);
    }
  };

  public get company() {
    return this.meta.company;
  }

  public get brandTypeLabel() {
    return this.brandType ? BrandTypes[this.brandType] : '';
  }

  // TODO: refactor(getChildDepthと融合）
  public getIngredientsDepth(ing:SpecIngredientEntity, depth = 0):number {
    if (!ing.parentId) return depth;
    const parent =  this.ingredients.find(i => i.id === ing.parentId)!;
    return this.getIngredientsDepth(parent, depth + 1);
  }
  // public getIngredientAmount(ing:SpecIngredientEntity):number {
  //   if (!ing.parentId) return ing.amount || 0;
  //   const children =  this.ingredients.filter(i => i.parentId === ing.id)!;
  //   return this.getIngredientAmount(parent);
  // }

  public get packageImages() :SpecAttachmentEntity[] {
    return this.attachments.filter(a => a.isPackageImage);
  };
  public get documentAttachments() :SpecAttachmentEntity[] {
    return this.attachments.filter(a => !a.isPackageImage);
  };
  public getThumbnail(): IFile|null {
    return this.getPackageImage(SpecPackageImagePreparedType.PieceFront);
  }
  public getPackageImage(key:string): IFile|null {
    const image = this.packageImages.find(pi => pi.key === key);
    if (!image) return null;
    return image.attachment;
  }
  public getDocumentAttachment(key:string): IFile|null {
    const document = this.documentAttachments.find(pi => pi.key === key);
    if (!document) return null;
    return document.attachment;
  }

  public getAllergenNamesUnique(visible: boolean | undefined = undefined) {
    const allergens = (function(ings) {
      if (visible === true) {
        return ings.flatMap(i => i.allergens.filter(a => a.isVisible).map(a => a.allergen));
      } else if (visible === false) {
        return ings.flatMap(i => i.allergens.filter(a => !a.isVisible).map(a => a.allergen));
      } else {
        return ings.flatMap(i => i.allergens.map(a => a.allergen));
      }
    })(this.ingredients);
    allergens.sort((a,b) => a.order - b.order);
    return uniq(allergens.map(a => getAllergenDisplayName(a.name)));
  }

  public getSelectionLabel(withApplyDate:boolean = false): string {
    let str = formatDate(this.createdAt, 'yy/MM/dd ') + (this.isPublished ? '更新分' : '下書き');
    str += (withApplyDate && !!this.applyDate) ? formatDate(this.applyDate, '（M月d日適用分）') : '';
    return str;
  }

  public setIngredientsChildrenWithMutating() {
    this.ingredients.forEach((ing: SpecIngredientEntity) => {
      if (ing.parentId) {
        const parent = this.ingredients.find(i => i.id === ing.parentId)!;
        parent.children.push(ing);
      }
    });
  }
  public get hydrogenatedOilSummary(): HydrogenatedOilType | null {
    const oils = this.ingredients.map(i => i.hydrogenatedOilType);

    if (oils.includes(HydrogenatedOilType.Both)) {
      return HydrogenatedOilType.Both;
    } else if (oils.includes(HydrogenatedOilType.PHOs) && oils.includes(HydrogenatedOilType.FHOs)) {
      return HydrogenatedOilType.Both;
    } else if (oils.includes(HydrogenatedOilType.PHOs)) {
      return HydrogenatedOilType.PHOs;
    } else if (oils.includes(HydrogenatedOilType.FHOs)) {
      return HydrogenatedOilType.FHOs;
    } else if (this.ingredients.every(ing => ing.hydrogenatedOilType === HydrogenatedOilType.None)) {
      return HydrogenatedOilType.None;
    } else {
      return null;
    }
  }
}

export const ValidatorRules = {
  name: [requiredOnBlurRule, createMaxStringRule()],
  nameKana: [createMaxStringRule()],
  foodType: [requiredOnChangeRule],
  areas: [],
  formulationAdditiveSynonymId: [ requiredOnChangeRule ],
  intraCode: [createMaxStringRule()],
  brandName: [createMaxStringRule()],
  aboutUrl: [createMaxStringRule(4096)],
  packingSpec: [createMaxStringRule()],
  packingForms: SpecPackingFormValidatorRules,
  alcoholRatio: [typeNumberOrEmptyRule, createMinRule(0, 'number'), createDecimalRule(9, 6)],
  certifications: SpecCertificationValidatorRules,
  isExpirationDetail: [ requiredRule ],

  paramSellerOperatorName: [createMaxStringRule()],
  paramSellerNote: [createMaxStringRule()],
  makerType: [requiredOnChangeRule],
  seller: PartnerValidatorRules,
  maker: PartnerValidatorRules,
  makerFactory: PartnerValidatorRules,

  ingredientCustomCol: [createMaxStringRule()],
  ingredients: IngredientValidatorRules,
  nameAsIngredient: [
    createMaxStringRule(),
    createProhibitedCharsRule(
      [',','、','(', ')','（','）'],
      'blur',
      "<ul class='u-list-style-disc'>" +
      "<li>ここには原材料の内訳を書かず、複合原材料として最も一般的な名称を書いてください。例えば、「マヨネーズ（植物油脂、卵黄、醸造酢...）」ではなく「マヨネーズ」のみとなります。</li>" +
      "<li>添加物製剤は、「◯◯製剤」など一般的な製剤名名称。添加物については、未記入としてください。</li>" +
      "</ul>"
    )
  ],
  noteForIngredientList: [createMaxStringRule(512)],

  expirationDaysBeforeOpen: [createMinRule(0), typeNonNegativeIntRule],
  expirationDaysAfterOpen: [createMinRule(0), typeNonNegativeIntRule],
  expirationDaysCalculationBasisBeforeOpen: [createMaxStringRule()],
  expirationDaysCalculationBasisAfterOpen: [createMaxStringRule()],

  label: LabelEntityValidatorRules,
  labelCustomValues: LabelCustomValueValidatorRules,

  nutrition: NutritionValidatorRules,
  packageMaterials: SpecPackingMaterialValidatorRules,
  packageCertificationMarks: SpecPackageCertificationMarkValidatorRules,
  packageGmoText: [createMaxStringRule()],
  packageMadeInArea: [createMaxStringRule()],
  packageContaminationAlertText: [createMaxStringRule()],
  riceTraceabilityDisplayPlace: [createMaxStringRule()],

  metalDetectorFe: [typeNumberOrEmptyRule, createDecimalRule(11, 6)],
  metalDetectorSus: [typeNumberOrEmptyRule, createDecimalRule(11, 6)],
  metalDetectorNonFe: [typeNumberOrEmptyRule, createDecimalRule(11, 6)],
  weightCheckerMin: [typeNumberOrEmptyRule, createDecimalRule(9, 3)],
  weightCheckerMax: [typeNumberOrEmptyRule, createDecimalRule(9, 3)],

  contaminationCheckMethod: [createMaxStringRule()],
  finalSanitizeMethod: [createMaxStringRule()],
  coolingMethod: [createMaxStringRule()],
  contaminationPreventionMeasure: [createMaxStringRule()],
  qualityPreservationMethods: QualityPreservationMethodTypeValidatorRules,

  manufactureProcesses: ProcessValidatorRules
};


export const BrandTypes = {
  1: BrandTypesLang.ナショナルブランド,
  2: BrandTypesLang.プライベートブランド,
  3: BrandTypesLang.インストア商品,
  4: BrandTypesLang.アウトパック商品,
  11: BrandTypesLang.留型,
  12: BrandTypesLang.特注,
}

export enum ExpirationTypeEnum {
  BestBy = 1,
  Expiration = 2,
  Qualification = 3,
  None = 0,
}
export const ExpirationTypes = {
  [ExpirationTypeEnum.BestBy]: i18n.t('賞味期限'),
  2: i18n.t('消費期限'),
  3: i18n.t('品質保証期限'),
  [ExpirationTypeEnum.None]: i18n.t('期限設定無し'),
} as {[key:number]:string};

export const ExpirationFormatTypeMap = new Map<number, string>([
  [15, "2019 年 5 月 1 日"],
  [30, "2019 年 5 月 01 日"],
  [22, "2019 年 05 月 01 日"],
  [4, "2019.5.1"],
  [31, "2019.5.01"],
  [23, "2019.05.01"],
  [32, "2019 5 01"],
  [5, "2019 05 01"],
  [46, "2019/5/1"],
  [47, "2019/5/01"],
  [48, "2019/05/01"],
  [19, "20190501"],
  [6, "19.5.1"],
  [33, "19.5.01"],
  [24, "19.05.01"],
  [34, "19 5 1"],
  [35, "19 5 01"],
  [7, "19 05 01"],
  [16, "190501"],
  [1, "令和 1 年 5 月 1 日"],
  [36, "令和 1 年 5 月 01 日"],
  [25, "令和 1 年 05 月 01 日"],
  [2, "1.5.1"],
  [37, "1.5.01"],
  [26, "1.05.01"],
  [38, "1 5 1"],
  [39, "1 5 01"],
  [3, "1 05 01"],
  [20, "10501"],
  [42, "2019年 5 月"],
  [43, "2019年 05 月"],
  // 以下は期限が製造日から3ヶ月以上の場合のみ使用できる
  [11, "2019.5"],
  [27, "2019.05"],
  [50, "2019 5"],
  [12, "2019 05"],
  [51, "2019/5"],
  [49, "2019/05"],
  [21, "201905"],
  [44, "19 年 5 月"],
  [45, "19 年 05 月"],
  [13, "19.5"],
  [28, "19.05"],
  [40, "19 5"],
  [14, "19 05"],
  [18, "1905"],
  [8, "令和 1 年 5 月"],
  [9, "1.5"],
  [29, "1.05"],
  [41, "1 5"],
  [10, "1 05"],
  [17, "105"],
]);

export function expirationDateFormatter(date:Date, type:number): string {
  // 令和3年
  const wareki = date.toLocaleDateString("ja-JP-u-ca-japanese", { year: 'numeric'});
  const warekiLabel = wareki.substr(0, 2);
  const warekiDigit = wareki.match(/\d+/) ? wareki.match(/\d+/)![0] : '';
  const year = date.getFullYear();
  const yearShort = date.getFullYear().toString().substr(-2);
  const month = date.getMonth() + 1;
  const monthZeroPad = month.toString().padStart(2, '0');
  const day = date.getDate();
  const dayZeroPad = date.getDate().toString().padStart(2, '0');

  switch (type) {
    case 15: return `${year} 年 ${month} 月 ${day} 日`;
    case 30: return `${year} 年 ${month} 月 ${dayZeroPad} 日`;
    case 22: return `${year} 年 ${monthZeroPad} 月 ${dayZeroPad} 日`;
    case 4: return `${year}.${month}.${day}`;
    case 31: return `${year}.${month}.${dayZeroPad}`;
    case 23: return `${year}.${monthZeroPad}.${dayZeroPad}`;
    case 32: return `${year} ${month} ${dayZeroPad}`;
    case 5: return `${year} ${monthZeroPad} ${dayZeroPad}`;
    case 46: return `${year}/${month}/${day}`;
    case 47: return `${year}/${month}/${dayZeroPad}`;
    case 48: return `${year}/${monthZeroPad}/${dayZeroPad}`;
    case 19: return `${year}${monthZeroPad}${dayZeroPad}`;
    case 6: return `${yearShort}.${month}.${day}`;
    case 33: return `${yearShort}.${month}.${dayZeroPad}`;
    case 24: return `${yearShort}.${monthZeroPad}.${dayZeroPad}`;
    case 34: return `${yearShort} ${month} ${day}`;
    case 35: return `${yearShort} ${month} ${dayZeroPad}`;
    case 7: return `${yearShort} ${monthZeroPad} ${dayZeroPad}`;
    case 16: return `${yearShort}${monthZeroPad}${dayZeroPad}`;
    case 1: return `${warekiLabel} ${warekiDigit} 年 ${month} 月 ${day} 日`;
    case 36: return `${warekiLabel} ${warekiDigit} 年 ${month} 月 ${dayZeroPad} 日`;
    case 25: return `${warekiLabel} ${warekiDigit} 年 ${monthZeroPad} 月 ${dayZeroPad} 日`;
    case 2: return `${warekiDigit}.${month}.${day}`;
    case 37: return `${warekiDigit}.${month}.${dayZeroPad}`;
    case 26: return `${warekiDigit}.${monthZeroPad}.${dayZeroPad}`;
    case 38: return `${warekiDigit} ${month} ${day}`;
    case 39: return `${warekiDigit} ${month} ${dayZeroPad}`;
    case 3: return `${warekiDigit} ${monthZeroPad} ${dayZeroPad}`;
    case 20: return `${warekiDigit}${monthZeroPad}${dayZeroPad}`;
    case 42: return `${year} 年 ${month} 月`;
    case 43: return `${year} 年 ${monthZeroPad} 月`;
    case 11: return `${year}.${month}`;
    case 27: return `${year}.${monthZeroPad}`;
    case 50: return `${year} ${month}`;
    case 12: return `${year} ${monthZeroPad}`;
    case 51: return `${year}/${month}`;
    case 49: return `${year}/${monthZeroPad}`;
    case 21: return `${year}${monthZeroPad}`;
    case 44: return `${yearShort} 年 ${month} 月`;
    case 45: return `${yearShort} 年 ${monthZeroPad} 月`;
    case 13: return `${yearShort}.${month}`;
    case 28: return `${yearShort}.${monthZeroPad}`;
    case 40: return `${yearShort} ${month}`;
    case 14: return `${yearShort} ${monthZeroPad}`;
    case 18: return `${yearShort}${monthZeroPad}`;
    case 8: return `${warekiLabel} ${warekiDigit} 年 ${month} 月`;
    case 9: return `${warekiDigit}.${month}`;
    case 29: return `${warekiDigit}.${monthZeroPad}`;
    case 41: return `${warekiDigit} ${month}`;
    case 10: return `${warekiDigit} ${monthZeroPad}`;
    case 17: return `${warekiDigit}${monthZeroPad}`;
    default: throw Error('expirationDateFormatter type is out of range: ' + type);
  }
}

import { StorageTemperatureRangeTypeLang as langS } from "@/lang/enum/storage-temperature-range-type-lang";
import {LabelEntityValidatorRules, LabelExtraFieldEnum} from "@/entities/interfaces/i-label-entity";
import {IHasNutritionExtra, NutritionExtra, NutritionExtraProp} from "@/entities/nutrition-extra-entity";
import uniqBy from "lodash/uniqBy";
import {getAllergenDisplayName} from "@/entities/allergy-entity";
import {AdditiveSynonymEntity} from "@/entities/additive-entity";
import {TAdditiveSynonymListItem} from "@/repositories/master/additive-repository";
import {SpecAreaEntity} from "@/entities/specs/spec-area-entity";
export const StorageTemperatureRangeTypeMap = new Map<number, string>([
  [1, langS["常温"]],
  [2, langS["常温（28℃以下）"]],
  [19, langS["常温（25℃以下）"]],
  [10, langS["常温（20℃以下）"]],
  [3, langS["冷蔵"]],
  [20, langS["冷蔵（15℃以下）"]],
  [4, langS["冷蔵（10℃以下）"]],
  [5, langS["冷蔵（4℃以下）"]],
  [11, langS["冷蔵（2℃以下）"]],
  [12, langS["冷蔵（0℃以下）"]],
  [6, langS["冷蔵（-5℃以下）"]],
  [7, langS["冷凍"]],
  [8, langS["冷凍（-15℃以下）"]],
  [9, langS["冷凍（-18℃以下）"]],
  [16, langS["冷凍（-30℃以下）"]],
  [17, langS["冷凍（-40℃以下）"]],
  [18, langS["冷凍（-50℃以下）"]],
]);

export enum SpecManufactureProcessInputType {
  Text = 1,
  TableSimple = 2,
  TableDetail = 3,
  Attachment = 4,
}

export enum PackingFormWeightUnitType {
  Gram = 1,
  KiloGram = 2,
  Ton = 3,
}
export const PackingFormWeightUnitTypeDict = {
  [PackingFormWeightUnitType.Gram]: 'g',
  [PackingFormWeightUnitType.KiloGram]: 'kg',
  [PackingFormWeightUnitType.Ton]: 't（トン）',
}

export const AlcoholRatioChangedEventKey = 'alcohol-ratio-changed';
export function dispatchAlcoholRatioChanged() {
  document.dispatchEvent(new Event('alcohol-ratio-changed'));
}

export enum ExpirationDateUnit {
  Day = 1,
  Month = 2,
  Year = 3,
  Hour = 4,
}
export const ExpirationDateUnitDict = {
  [ExpirationDateUnit.Day]: '日',
  [ExpirationDateUnit.Month]: 'ヶ月',
  [ExpirationDateUnit.Year]: '年',
  [ExpirationDateUnit.Hour]: '時間',
}

export const VisibleAdditionalIngredientColumn = {
  Area: 'area',
  Manufacturer: 'manufacturer',
  Supplier: 'supplier',
  OriginMaterial: 'origin_material',
  HydrogenatedOil: 'hydrogenated_oils',
} as const;
export type VisibleAdditionalIngredientColumn = typeof VisibleAdditionalIngredientColumn[keyof typeof VisibleAdditionalIngredientColumn];
export const VisibleAdditionalIngredientColumnDict = {
  [VisibleAdditionalIngredientColumn.Area]: '原産地（最終加工地）',
  [VisibleAdditionalIngredientColumn.Manufacturer]: '製造者名',
  [VisibleAdditionalIngredientColumn.Supplier]: '仕入元名',
  [VisibleAdditionalIngredientColumn.OriginMaterial]: '基原原料',
  [VisibleAdditionalIngredientColumn.HydrogenatedOil]: '水素添加油脂',
}
