import ObjectUtils from "@/utils/object-utils";
import {createMaxRule, requiredRule} from "@/utils/validation-rules";

export default class IngredientMadeInAreaEntity {
  public madeInAreaId:number|null = null;
  public madeInArea?:MadeInAreaEntity;
  public isDomestic?:boolean;
  public customName:string|null = null;

  private _model:string = '';

  constructor(init:Partial<IngredientMadeInAreaEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.madeInArea) {
      this.madeInArea = new MadeInAreaEntity(init.madeInArea);
    }

    if (this.madeInAreaId || this.customName) {
      this._model = (this.isDomestic ? "domestic-" : 'foreign-') +
        (this.madeInAreaId ? this.madeInAreaId.toString() : 'null');
    }
  }

  public get model():string {
    return this._model;
  }
  public set model(val:string) {
    const match = val.match(/^(domestic|foreign)-(null|\d+)$/)!;
    this.isDomestic = match[1] === 'domestic';
    this.madeInAreaId = match[2] === 'null' ? null : Number(match[2]);
    this._model = val;
  }
  public get isCustom() {
    return this.model &&
      this.model.match(/^(domestic|foreign)-(null|\d+)$/)![2] === 'null';
  }

  public getLabelDisplayName(isProcessed): string {
    if (this.madeInArea) {
      return this.madeInArea.getLabelDisplayName(isProcessed);
    } else {
      return this.customName!;
    }
  }
}

export const AreaSuffixForProcess = '製造';

export class MadeInAreaEntity {
  public id!:number;
  public name!:string;
  public countryFullname!:string|null;
  public synonym1!:string|null;
  public countryType!:AreaCountryType;
  public type!:AreaType;

  public get isJapanDomestic(): boolean {
    return this.countryType === AreaCountryType.Japan;
  }

  constructor(init:Partial<MadeInAreaEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);
  }

  public getLabelDisplayName(isProcessed: boolean) {
    const isCountryJapan = this.type === AreaType.COUNTRY && this.countryType === AreaCountryType.Japan;
    if (isProcessed) {
      return isCountryJapan ? `国内${AreaSuffixForProcess}` : `${this.name}${AreaSuffixForProcess}`;
    } else {
      return isCountryJapan ? '国産' : this.name;
    }
  }

  public get searchKey() {
    return `${this.name} ${this.countryFullname || ''} ${this.synonym1 || ''}`.trim();
  }
}

export enum AreaType {
  PREFECTURE = 1,
  COUNTRY = 2,
}
export enum AreaCountryType {
  Japan = 1,
}

export const ValidatorRules:any = {
  model: [requiredRule],
  customName: [requiredRule, createMaxRule(32, 'char')]
};
